import assest from "@/json/assest";
import Image from "next/image";
import React from "react";
import styles from "@/styles/layout/_footer.module.scss";
import { Box, Container, Grid, List, ListItem, Typography } from "@mui/material";
import Link from "next/link";
import ScrollToTop from "react-scroll-to-top";
import { useRouter } from "next/router";

const FooterMenu = [
  {
    name: "home",
    route: "/",
  },
  {
    name: "about us",
    route: "/about",
  },
  {
    name: "Careers",
    route: "/careers",
    childRoute: "/careerdetails",
  },
  {
    name: "Our Homes",
    route: "/ourhomes",
  },
  {
    name: "Services",
    route: "/services",
  },
  {
    name: "Contact",
    route: "/contact",
  },
]

const Footer = () => {
  const router = useRouter();
  return (
    <footer className={styles.pageFooter}>
      <ScrollToTop smooth component={<Image src={assest.ArwUp} width={12} height={12} alt='icon' />} />
      <Container fixed>
        <Box sx={{ flexGrow: 1 }} className={styles.footerBox}>
          <Grid container spacing={2}>
            <Grid item md={12} lg={7}>
              <List>
                {FooterMenu.map((item, index) => {
                  return (
                    <ListItem
                      key={index}
                      className={
                        router.pathname === item?.route ||
                          router.pathname === item?.childRoute
                          ? "active"
                          : ""
                      }
                    >
                      <Link href={item.route}>{item.name}</Link>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid item md={12} lg={5}>
              <Typography variant="body1">Copyright @2023 AAA Residencies, All rights reserved</Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
