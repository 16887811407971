import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useAppDispatch } from "@/hooks/useAppDispatch";
import { useAppSelector } from "@/hooks/useAppSelector";
import { logout } from "@/reduxtoolkit/slices/userSlice";
import styles from "@/styles/layout/header.module.scss";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { Button, Container } from "@mui/material";
import Image from "next/image";
import assest from "@/json/assest";
import { checkWindow } from "@/lib/functions/_helpers.lib";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const CustomButton = dynamic(() => import("@/ui/Buttons/CustomButton"));

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = [
  {
    name: "home",
    route: "/",
  },
  // {
  //   name: "Login",
  //   route: "/login"
  // },
  {
    name: "about us",
    route: "/about",
  },
  {
    name: "Careers",
    route: "/careers",
    childRoute: "/careerdetails",
  },
  {
    name: "Our Homes",
    route: "/ourhomes",
  },
  {
    name: "Services",
    route: "/services",
  },
  {
    name: "Contact",
    route: "/contact",
  },
  // {
  //   name: "careerDetails",
  //   route: "/careerDetails",
  // },
];

export default function Header(props: Props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { userData, isLoggedIn } = useAppSelector((state) => state.userSlice);
  const dispatch = useAppDispatch();
  const router = useRouter();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    router.push("/login");
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center" }}
      className={styles.mobDrawer}
    >
      <Box className={styles.drawerLogo}>
        <Link href="/">
          <Image src={assest.Logo} width={100} height={32} alt="logo" />
        </Link>
        <Button><KeyboardBackspaceIcon /></Button>
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <Link href={item?.route} key={item.name}>
            <ListItem disablePadding
              className={
                router.pathname === item?.route ||
                  router.pathname === item?.childRoute
                  ? "active"
                  : ""
              }>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  const container = checkWindow() ? () => window?.document?.body : undefined;

  const addClassToNavbar = () => {
    if (checkWindow()) {
      const _doc = document.getElementById("navbar_id");
      if (window.scrollY > 100) {
        _doc?.classList.add("navbar_bg");
      } else {
        _doc?.classList.remove("navbar_bg");
      }
    }
  };

  React.useEffect(() => {
    if (checkWindow()) {
      window.addEventListener("scroll", addClassToNavbar);

      return () => {
        window.removeEventListener("scroll", () => { });
      };
    }
  }, []);

  return (
    <Box sx={{ display: "flex" }} className={styles.toolbarHeader}>
      <AppBar
        component="nav"
        id="navbar_id"
        elevation={0}
        className={styles.headerContainer}
      // sx={{backgroundColor:color}}
      >
        <Container fixed>
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1 }} className={styles.logoMain}>
              <Link href="/">
                <Image src={assest.Logo} width={237} height={78} alt="logo" />
              </Link>
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
              className={styles.menuBtn}
            >
              <MenuIcon />
            </IconButton>
            <Box className={styles.headerMenu}>
              <List>
                {navItems.map((item, index) => {
                  return (
                    <ListItem
                      key={index}
                      className={
                        router.pathname === item?.route ||
                          router.pathname === item?.childRoute
                          ? "active"
                          : ""
                      }
                    >
                      <Link href={item.route}>{item.name}</Link>
                    </ListItem>
                  );
                })}
              </List>
            </Box>

            {/* {isLoggedIn ? (
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <CustomButton onClick={handleLogout} type="button" variant="text">
                  <span>Logout</span>
                </CustomButton>

                <CustomButton type="button" variant="text">
                  <span>{userData?.email}</span>
                </CustomButton>
              </Box>
            ) : (
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                {navItems.map((item) => (
                  <Link href={item?.route} key={item.name}>
                    <CustomButton type="button" variant="text">
                      <span>{item?.name}</span>
                    </CustomButton>
                  </Link>
                ))}
              </Box>
            )} */}
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Toolbar />
    </Box>
  );
}
