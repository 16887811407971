import assest from "@/json/assest";
import Wrapper from "@/layout/wrapper/Wrapper";
import CustomButton from "@/ui/Buttons/CustomButton";
import SkyButton from "@/ui/Buttons/SkyButton";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { yupResolver } from "@hookform/resolvers/yup";
import Image from "next/image";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WhatPeopleSay from "@/components/WhatPeopleSay/WhatPeopleSay";
import Seo from "@/components/Seo/Seo";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import EnquaryModal from "@/components/modal/EnquaryModal";
import CancelIcon from "@mui/icons-material/Cancel";
import Link from "next/link";
import * as yup from "yup";
import validationText from "@/json/messages/validationText";
import useNotiStack from "@/hooks/useNotistack";
import { SubmitHandler, useForm } from "react-hook-form";
const HomeSection = dynamic(() => import("@/components/HomeSection"), {
  ssr: true,
});

const HomeBanner = styled(Box)`
  .MuiGrid-container {
    align-items: center;
  }
`;

const BannerLeftContent = styled(Box)`
  @media (max-width: 899px) {
    text-align: center;
  }

  .MuiTypography-h1 {
    font-size: 65px;
    margin-bottom: 35px;
    position: relative;
    z-index: 9;

    @media (max-width: 1399px) {
      font-size: 52px;
    }

    @media (max-width: 899px) {
      font-size: 40px;
      margin-bottom: 15px;
    }

    @media (max-width: 599px) {
      font-size: 28px;
    }

    span {
      color: #73c2fb;
    }
  }
`;

const BannerRightContent = styled(Box)`
  position: relative;

  @media (max-width: 899px) {
    padding: 0 0 50px 0;
  }
  .homeBannerThhumb {
    position: relative;
    .thumbBanner {
      border-radius: 15px;
    }

    .imgDots {
      position: absolute;
      right: -105px;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;

      @media (max-width: 1399px) {
        right: -15px;
      }
    }
  }
`;

const BannerCard = styled(Paper)`
  width: 100%;
  max-width: 270px;
  background: #73c2fb;
  border: 10px solid #ffffff;
  border-radius: 15px;
  box-shadow: none;
  padding: 55px 20px;
  position: absolute;
  bottom: -35px;
  left: -22%;

  @media (max-width: 899px) {
    left: -15px;
  }

  @media (max-width: 599px) {
    max-width: 250px;
    padding: 20px;
  }

  .MuiTypography-body1 {
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    line-height: 1.6;

    @media (max-width: 599px) {
      font-size: 14px;
    }
  }
`;

const HomeCount = styled(Box)`
  text-align: center;

  @media (max-width: 899px) {
    padding-top: 30px !important;
  }

  .MuiContainer-root {
    position: relative;
  }
`;

const CountStack = styled(Stack)`
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 930px;
  margin: 0 auto;

  .pageBlueDot {
    position: absolute;
    left: -14%;
    bottom: 100%;

    @media (max-width: 1199px) {
      display: none;
    }
  }
`;

const ExpBox = styled(Box)`
  @media (max-width: 899px) {
    width: 100%;
    max-width: 50%;
    margin-top: 30px;
  }

  .MuiTypography-body1 {
    font-size: 18px;
    color: #1e294c;

    font-family: "Ubuntu", sans-serif;
    line-height: 1;
    margin-bottom: 18px;

    @media (max-width: 599px) {
      font-size: 15px;
    }
  }

  .MuiTypography-body2 {
    font-weight: 600;
    font-size: 14px;
    color: #a6abbc;
    line-height: 1;
  }
`;

const TakeCare = styled(Box)`
  .MuiGrid-container {
    align-items: center;
  }

  .about-inr {
    padding-left: 30px;
    position: relative;
    z-index: 1;

    @media (max-width: 899px) {
      padding: 30px 0 0;
      text-align: center;
    }
  }

  .fullTake {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: -70px;
      width: 100%;
      height: 100%;
      background: url(/assets/images/shadow-round.png) no-repeat;
      background-position: 0% 40%;
      background-size: 50%;

      @media (max-width: 899px) {
        background-position: 60% -10%;
        background-size: 100%;
      }
    }
  }
`;

const LeftSince = styled(Box)`
  img {
    position: relative;
    z-index: 1;
  }
`;

const HouseCollection = styled(Box)`
  .MuiGrid-container {
    align-items: center;
  }

  .partRight {
    position: relative;

    .pageBlueDot {
      position: absolute;
      right: -28%;
      top: 50%;
      transform: translateY(-50%);

      @media (max-width: 1500px) {
        right: -15%;
      }

      @media (max-width: 1365px) {
        right: -2%;
        width: 150px;
        height: auto;
      }

      @media (max-width: 1199px) {
        width: 100px;
      }
    }
  }
`;

const CollectionLeft = styled(Box)``;

const CollectionRight = styled(Stack)`
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -18px;

  @media (max-width: 599px) {
    margin: 0;
  }
`;

const HouseBox = styled(Box)`
  flex-basis: 33.33%;
  max-width: 33.33%;
  padding: 0 18px;

  @media (max-width: 599px) {
    padding: 0 8px;
  }

  &:nth-child(2) {
    margin-top: 50px;
  }

  .cutWrapper {
    position: relative;
    line-height: 0;
    transition: 0.3s all ease-in-out 0s;
    border: 6px solid #ffffff;
    box-shadow: 0px 50px 78px rgba(0, 0, 0, 0.02),
      0px 1.8148px 0.8519px rgba(0, 0, 0, 0.0151852),
      0px 6.5185px 2.4815px rgba(0, 0, 0, 0.0121481),
      0px 16px 9px rgba(0, 0, 0, 0.01),
      0px 1.14815px 1.51852px rgba(0, 0, 0, 0.00785185),
      0px 0.85185px 0.14815px rgba(0, 0, 0, 0.00481481);
    border-radius: 15px;

    .innerContentTxt {
      height: 94%;
      width: 82%;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 15px;
      transform: translate(-50%, -50%);
      transition: 0.3s all ease-in-out 0s;
    }

    &:hover {
      .innerContentTxt {
        background: rgba(115, 194, 251, 0.65);
      }
    }

    .MuiTypography-body2 {
      transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      position: absolute;
      top: 50%;
      left: 50%;
      font-weight: 500;
      font-size: 30px;
      color: #ffffff;
      text-transform: uppercase;
      font-family: "Ubuntu", sans-serif;

      @media (max-width: 575px) {
        font-size: 22px;
      }
    }

    img {
      border-radius: 15px;
      width: 100%;
      height: auto;
    }
  }
`;

const OurClients = styled(Box)`
  background: linear-gradient(
    180deg,
    rgba(198, 198, 198, 0.08) 0%,
    rgba(198, 198, 198, 0) 100%
  );
  padding: 80px 0;

  @media (max-width: 599px) {
    padding: 50px 0;
  }
`;

const ClientHeader = styled(Box)``;

const ClientSlider = styled(Box)`
  position: relative;

  .people-inr {
    img {
      border-radius: 20px;
    }
  }

  .slick-slide {
    padding: 0 15px;

    .people-slide {
      .people-inr {
        img {
          border-radius: 20px;
        }
      }
    }
  }

  .slick-list {
    padding: 50px 0 90px;
    margin: 0 -15px;
  }

  .slick-prev,
  .slick-next {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    border: 1px solid #ededed;
    background: #ffffff;
    transform: none;
    top: inherit;
    bottom: 0;
    z-index: 9;

    &::before {
      content: "";
      width: 10px;
      position: absolute;
      height: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      filter: invert(32%) sepia(15%) saturate(1356%) hue-rotate(166deg)
        brightness(103%) contrast(97%);
      opacity: 1 !important;
    }
  }

  .slick-arrow {
    background-color: #c6e6fc;
    border: none;

    &:hover,
    &:focus {
      background-color: #c6e6fc;
      border: none;
    }
  }

  .slick-disabled {
    border: 1px solid #ededed;
    background: #ffffff;

    &:hover,
    &:focus {
      border: 1px solid #ededed;
      background: #ffffff;
    }

    &::before {
      filter: inherit;
    }
  }

  .slick-prev {
    left: 0;

    @media (max-width: 599px) {
      left: 25%;
    }

    &::before {
      background: url(../assets/images/slider-prev.svg) no-repeat;
    }
  }
  .slick-next {
    left: 75px;
    @media (max-width: 599px) {
      left: 54%;
    }
    &::before {
      background: url(../assets/images/slider-next.svg) no-repeat;
    }
  }

  .expBtn {
    position: absolute;
    bottom: 0;
    right: 0;

    @media (max-width: 599px) {
      position: inherit;
      text-align: center;
      margin-top: 30px;
    }
  }
`;

const AuthorPaper = styled(Paper)`
  padding: 30px 25px;
  box-shadow: 0px 34px 54px rgba(0, 0, 0, 0.03);
  border-radius: 20px;
  z-index: 1;
  position: relative;
  width: 100%;
  max-width: 92%;
  margin: -50px auto 0;

  @media (max-width: 1199px) {
    padding: 15px;
  }

  .MuiTypography-h3 {
    font-weight: 500;
    font-size: 18px;
    text-transform: capitalize;
    color: #000000;
    line-height: 1.5;
    min-height: 55px;
    margin-bottom: 10px;
    font-family: "Ubuntu", sans-serif;
  }

  .MuiTypography-body2 {
    color: #555555;
    line-height: 1.8;
  }
`;

const JoinTeam = styled(Box)``;

const TeamWrapper = styled(Stack)`
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #1e294c;
  border-radius: 20px;
  padding: 60px;
  margin-top: -100px;

  @media (max-width: 1199px) {
    justify-content: center;
  }

  @media (max-width: 599px) {
    padding: 30px;
  }

  .MuiTypography-h2 {
    max-width: 684px;
    color: #ffffff;

    @media (max-width: 1199px) {
      text-align: center;
      margin-bottom: 20px;
    }

    @media (max-width: 599px) {
      font-size: 25px;
    }

    span {
      color: #73c2fb;
    }
  }
`;

const HomeContactForm = styled(Box)`
  .MuiTextField-root {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    input {
      box-sizing: border-box;
      height: 70px;
      cursor: pointer;
    }

    textarea {
      cursor: pointer;
    }

    .MuiInputLabel-root {
      /* transform: translate(14px, 22px) scale(1); */
      color: #65676a;
      font-weight: 400;
      font-size: 16px;
      padding-top: 8px;

      &.MuiInputLabel-shrink {
        /* transform: translate(14px, -9px) scale(0.75); */
        padding-top: 0;
      }
    }

    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.23);
        border-width: 1px;
      }
    }
  }

  .MuiTypography-h2 {
    margin-bottom: 32px;
  }
`;

const FromLeft = styled(Box)`
  .MuiButtonBase-root {
    width: 100%;
    margin-top: 45px;
  }
`;

const FromRight = styled(Box)`
  iframe {
    width: 100%;
    height: 172px;
    border: none;
  }

  .MuiList-root {
    padding: 30px 0 0;

    .MuiListItem-root {
      align-items: center;
      padding: 0;

      &:not(:last-child) {
        margin-bottom: 35px;
      }

      .MuiTypography-body1 {
        position: relative;
        color: rgba(0, 0, 0, 0.6);
        font-family: "Ubuntu", sans-serif;
        margin-left: 14px;
        padding-left: 15px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 1px;
          height: 36px;
          background: #000;
        }

        a {
          color: rgba(0, 0, 0, 0.6);
          font-family: "Ubuntu", sans-serif;
        }
      }
    }
  }
`;

const People = styled(Box)`
  .people-sec {
    padding-bottom: 215px;

    @media (max-width: 599px) {
      padding-bottom: 170px;
    }
  }
`;

const sliderLoop = [
  {
    sliderContent:
      "Individual and group sessions by a licensed clinical therapist.",
    authorImage: `${assest.client01}`,
    authorName: "Therapy (coming soon) ",
  },
  {
    sliderContent:
      "Build social and life skills with our program’s counseling staff.",
    authorImage: `${assest.client02}`,
    authorName: "Life & Social Skills ",
  },
  // {
  //   sliderContent: "Developing a specific plan of action (ISP) .",
  //   authorImage: `${assest.client03}`,
  //   authorName: 'Treatment Planning & Case Management ',
  // },
  {
    sliderContent: "Weekly outings planned by both residents and staff.",
    authorImage: `${assest.client05}`,
    authorName: "Recreational Activities",
  },
];

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 899,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
//=== TYPES ====
interface IFormInput {
  name: string;
  email: string;
  subject: string;
  message: string;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email(validationText.error.email_format)
    .required(validationText.error.enter_email),
  name: yup.string().required(validationText.error.name),
  subject: yup.string().required(validationText.error.subject),
  message: yup.string().required(validationText.error.message),
});

// === get serverside methods ===4
export const getServerSideProps: GetServerSideProps = async (context) => {
  return {
    props: {},
  };
};

export default function Home() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const [value, setValue] = useState<number | null>(2);

  const { toastSuccess, toastError } = useNotiStack();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInput> = (data: any) => {
    // =====>sendgrid<=====

    setIsLoading(true);
    fetch("/api/sendgrid", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 200) {
          toastSuccess("Message sent successfully");
          reset();
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toastError(err?.message);
        setIsLoading(false);
      });
  };

  return (
    <Wrapper>
      <Seo title="Home" canonical={""} description={""} url={""} image={""} />
      <Box className="homeBannerSec">
        <Box className="bannerShape"></Box>
        <Container fixed>
          <HomeBanner sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} className="order2">
                <BannerLeftContent>
                  <Typography variant="h1">
                    <span>Find Luxury </span> and Residential Living Care
                  </Typography>
                  <Link href="mailto:info@aaaresidencies.com">
                    <SkyButton type="button">
                      <Typography variant="button">
                        <Image
                          src={assest.BtnIcon}
                          width={20}
                          height={20}
                          alt="icon"
                        />{" "}
                        Inquire Now
                      </Typography>
                    </SkyButton>
                  </Link>
                </BannerLeftContent>
              </Grid>
              <Grid item xs={12} md={6} className="order1">
                <BannerRightContent>
                  <Box className="homeBannerThhumb">
                    <Image
                      src={assest.homeimg11}
                      width={508}
                      height={725}
                      alt="icon"
                      layout="responsive"
                      className="thumbBanner"
                      priority
                    />
                    <Image
                      src={assest.blackDot}
                      width={187}
                      height={196}
                      alt="icon"
                      className="imgDots"
                    />
                    <BannerCard>
                      <Image
                        src={assest.patientsIcon}
                        width={46}
                        height={46}
                        alt="icon"
                      />
                      <Typography variant="body1">
                        Our clients receive care and attention 24 hours a day
                      </Typography>
                    </BannerCard>
                  </Box>
                </BannerRightContent>
              </Grid>
            </Grid>
          </HomeBanner>
        </Container>
      </Box>
      <HomeCount className="cmn_gap">
        <Container fixed>
          <CountStack direction="row">
            <Image
              src={assest.BlueDot}
              width={187}
              height={196}
              alt="dot"
              className="pageBlueDot"
            />
            <ExpBox>
              <figure>
                {" "}
                <Image
                  src={assest.icon14}
                  width={70}
                  height={70}
                  alt="dot"
                  className=""
                />
              </figure>
              <Typography variant="body1">Deep industry experience</Typography>
            </ExpBox>
            <ExpBox>
              <figure>
                {" "}
                <Image
                  src={assest.icon12}
                  width={70}
                  height={70}
                  alt="dot"
                  className=""
                />
              </figure>
              <Typography variant="body1">Satisfied clients</Typography>
            </ExpBox>
            <ExpBox>
              <figure>
                {" "}
                <Image
                  src={assest.icon13}
                  width={70}
                  height={70}
                  alt="dot"
                  className=""
                />
              </figure>
              <Typography variant="body1">Luxury living services</Typography>
            </ExpBox>
            <ExpBox>
              <figure>
                {" "}
                <Image
                  src={assest.icon16}
                  width={70}
                  height={70}
                  alt="dot"
                  className=""
                />
              </figure>
              <Typography variant="body1">Personalized Care </Typography>
            </ExpBox>
          </CountStack>
        </Container>
      </HomeCount>
      <TakeCare>
        <Container fixed>
          <Box sx={{ flexGrow: 1 }} className="fullTake">
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <LeftSince>
                  <Image
                    src={assest.reviewstatic}
                    width={1308}
                    height={1540}
                    layout="responsive"
                    alt="image"
                  />
                </LeftSince>
              </Grid>
              <Grid item xs={12} md={7}>
                <Box className="about-inr">
                  <Typography variant="h2" className="cmn-head">
                    We care like family with
                    <br /> <span> attention and detail</span>
                  </Typography>
                  <Typography variant="body1">
                    AAA Residencies was founded in 2019 with one major goal: to
                    provide individuals with intellectual and developmental
                    disabilities the resources, care, and opportunities to
                    become happy, confident, and successful in their lives.
                  </Typography>
                  <Box className="btnOutr">
                    <Link href="/about">
                      <CustomButton type="button">
                        <Typography variant="button">Read more</Typography>
                      </CustomButton>
                    </Link>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </TakeCare>
      <HouseCollection className="cmn_gap">
        <Container fixed>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} className="order2">
                <CollectionLeft>
                  <Typography variant="h2" className="cmn-head">
                    It starts with our <br /> <span>luxurious homes</span>
                  </Typography>
                  <Typography variant="body1">
                    Our residential group homes are high quality, clean, and
                    organized. We pride ourselves in providing an ultimately
                    luxurious, safe, comfortable, and happy experience for our
                    clients. We want to be a partner in our client's growth and
                    success. We will not stop until that is achieved. Schedule a
                    tour now!
                  </Typography>
                  <Box className="btnOutr">
                    {/* <CustomButton type="button">
                      <Typography variant="button">
                        Inquire
                      </Typography>
                    
                    </CustomButton> */}
                    <Link
                      href="mailto:info@aaaresidencies.com"
                      className="cmn_btn"
                    >
                      <Image
                        src={assest.btnimg}
                        alt="btn"
                        width={20}
                        height={20}
                      />
                      Inquire
                    </Link>
                  </Box>
                </CollectionLeft>
              </Grid>
              <Grid item xs={12} md={6} className="order1">
                <Box className="partRight">
                  <Image
                    src={assest.BlueDot}
                    width={187}
                    height={196}
                    alt="dot"
                    className="pageBlueDot"
                  />
                  <CollectionRight direction="row">
                    <HouseBox>
                      <Box className="cutWrapper">
                        <Image
                          src={assest.House01}
                          width={158}
                          height={510}
                          alt="house"
                        />
                        <Box className="innerContentTxt">
                          <Typography variant="body2">Peaceful</Typography>
                        </Box>
                      </Box>
                    </HouseBox>
                    <HouseBox>
                      <Box className="cutWrapper">
                        <Image
                          src={assest.House02}
                          width={158}
                          height={510}
                          alt="house"
                        />
                        <Box className="innerContentTxt">
                          <Typography variant="body2">Hygenic</Typography>
                        </Box>
                      </Box>
                    </HouseBox>
                    <HouseBox>
                      <Box className="cutWrapper">
                        <Image
                          src={assest.House03}
                          width={158}
                          height={510}
                          alt="house"
                        />
                        <Box className="innerContentTxt">
                          <Typography variant="body2">Luxurious</Typography>
                        </Box>
                      </Box>
                    </HouseBox>
                  </CollectionRight>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </HouseCollection>
      <OurClients>
        <Container fixed>
          <ClientHeader sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{
                xs: 2,
                sm: 2,
                md: 4,
              }}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h2" className="cmn-head">
                  What we think is all about <span>our client purpose</span>{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">
                  Our mission is to empower, support, and provide for community
                  members with intellectual and developmental disabilities and
                  help them become successful in all aspects of life.
                </Typography>
              </Grid>
            </Grid>
          </ClientHeader>
          <ClientSlider>
            <Slider {...settings} className="people-slider">
              {sliderLoop.map((item, index) => {
                return (
                  <Box className="people-slide" key={index}>
                    <Box className="people-inr">
                      <Image
                        src={item.authorImage}
                        width={358}
                        height={394}
                        layout="responsive"
                        alt={""}
                      />
                      <AuthorPaper>
                        <Typography variant="h3">{item.authorName}</Typography>
                        <Typography variant="body2">
                          {item.sliderContent}
                        </Typography>
                      </AuthorPaper>
                    </Box>
                  </Box>
                );
              })}
            </Slider>
            <Box className="expBtn">
              {/* <CustomButton type="button"><Typography variant="button">Inquire</Typography></CustomButton> */}
              <Link href="mailto:info@aaaresidencies.com" className="cmn_btn">
                <Image src={assest.btnimg} alt="btn" width={20} height={20} />
                Inquire
              </Link>
            </Box>
          </ClientSlider>
        </Container>
      </OurClients>
      <People>
        <WhatPeopleSay />
      </People>
      <JoinTeam>
        <Container fixed>
          <TeamWrapper direction="row">
            <Typography variant="h2">
              Join our team to start making a{" "}
              <span>difference in our client's lives</span>
            </Typography>
            <SkyButton type="button" onClick={handleClickOpen2}>
              <Typography variant="button">Start Now</Typography>
            </SkyButton>
          </TeamWrapper>
        </Container>
      </JoinTeam>
      <HomeContactForm className="cmn_gap">
        <Container fixed>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={7}>
                <FromLeft>
                  <Typography variant="h2">Get in touch</Typography>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} lg={5}>
                          <TextField
                            id="outlined-basic"
                            label="Name"
                            {...register("name")}
                            className={errors?.name?.message ? "formRootHide" : "newFormRoot"}
                          />
                          <span className="errormesg">
                            {errors?.name?.message}
                          </span>
                          <TextField
                            id="outlined-basic"
                            label="Email"
                            {...register("email")}
                            className={errors?.email?.message ? "formRootHide" : "newFormRoot"}
                          />
                          <span className="errormesg">
                            {errors?.email?.message}
                          </span>
                          <TextField
                            id="outlined-basic"
                            label="Subject"
                            {...register("subject")}
                            className={errors?.subject?.message ? "formRootHide" : "newFormRoot"}
                          />
                          <span className="errormesg">
                            {errors?.subject?.message}
                          </span>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Message"
                            multiline
                            rows={10.3}
                            {...register("message")}
                            className={errors?.message?.message ? "formRootHide" : "newFormRoot"}
                          />
                          <span className="errormesg">
                            {errors?.message?.message}
                          </span>
                        </Grid>
                      </Grid>
                    </Box>
                    <CustomButton type="Submit"    loading={isLoading} disabled={isLoading}>
                      <Typography variant="button">
                        {
                          isLoading?'Sending...':'Send Message'
                        }
                       </Typography>
                    </CustomButton>
                  </form>
                </FromLeft>
              </Grid>
              <Grid item xs={12} md={5}>
                <FromRight>
                  <Typography variant="h2">Our location</Typography>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d24880.805596247104!2d-77.1507372!3d38.7843258!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7b27d0584a713%3A0xa0bd79bf1ac6afb3!2s6094%20Franconia%20Rd%2C%20Franconia%2C%20VA%2022310%2C%20USA!5e0!3m2!1sen!2sin!4v1678777680905!5m2!1sen!2sin"
                    width={100}
                    height={172}
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                  <List>
                    <ListItem>
                      <Image
                        src={assest.location}
                        alt="location"
                        width={20}
                        height={26}
                      />
                      <Typography variant="body1">
                        6094 Franconia Rd,Suite A, Alexandria, VA 22310
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Image
                        src={assest.mail}
                        alt="location"
                        width={20}
                        height={26}
                      />
                      <Typography variant="body1">
                        <a href="mailto:info@aaaresidencies.com">
                          info@aaaresidencies.com
                        </a>
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Image
                        src={assest.call}
                        alt="location"
                        width={20}
                        height={26}
                      />
                      <Typography variant="body1">
                        <a href="tel:+7033362018">703-336-2018</a>
                      </Typography>
                    </ListItem>
                  </List>
                </FromRight>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </HomeContactForm>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        className="vdoModal"
      >
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            <CancelIcon />
          </Button>
        </DialogActions>
        <DialogContent>
          <video autoPlay width="100%">
            <source src="../assets/images/homevideo.mp4" />
          </video>
        </DialogContent>
      </Dialog>
      <EnquaryModal open={open2} onClose={handleClose2} />
    </Wrapper>
  );
}
