import assest from "@/json/assest";
import Wrapper from "@/layout/wrapper/Wrapper";
import CustomButton from "@/ui/Buttons/CustomButton";
import { Avatar, Grid, List, ListItem, Typography } from "@mui/material";
import { Box, Container, styled } from "@mui/system";
import Image from "next/image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SlickSliderWrapper from "@/ui/Slick/SlickSliderWrapper";

const PeopleSec = styled(Box)`
  .people-inr {
    padding: 41px;
    background: #ffffff;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1048);
    border-radius: 10px;

    @media (max-width: 599px) {
        padding: 20px;
    }
    .people-btm {
      display: flex;
      align-items: center;
      margin-top: 40px;
      .people-avatar {
        height: 54px;
        width: 54px;
        border-radius: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .people-hdr {
      padding-left: 8px;
      h4 {
        font-weight: 500;
        font-size: 18px;
        color: #5e6282;
        margin-bottom: 4px;
      }
      h5 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #5e6282;
      }
    }
  }
  .people-slider {
    margin: -20px;
    position: inherit;

    @media (max-width: 899px) {
        position: relative;
    }

    @media (max-width: 599px) {
        margin: 0;
    }

    .slick-slide {
      padding: 20px;
    }

    .slick-arrow:before {
      display: none;
    }

    .slick-arrow {
      height: 20px;
      width: 20px;

      @media (max-width: 899px) {
        display: none !important;
      }
    }

    .slick-prev {
      background-image: url("/assets/images/arrow-lft.svg");
      background-position: center;
      background-size: 10px;
      background-repeat: no-repeat;
      right: 60px;
      left: auto;
      top: -25px;
      bottom: auto;
      transform: none;
    }

    .slick-next {
      background-image: url("/assets/images/arrow-rgt.svg");
      background-position: center;
      background-size: 10px;
      background-repeat: no-repeat;
      right: 20px;
      top: -25px;
      left: auto;
      bottom: auto;
      transform: none;
    }

    .slick-dots li button:before {
      display: none;
    }

    .slick-dots li button{
      background-color: #E5E5E5;
      height: 12px;
      width: 12px;
      border-radius: 100%;
      font-size: 0;
    }
    
    .slick-dots li.slick-active button{
      background-color: #39425D;
    }
  }
  .people-sec{
    background: rgba(198, 198, 198, 0.08);

    .people-outr {
      position: relative;

      @media (max-width: 899px) {
        position: inherit;
      }
      /* .MuiGrid-container {
        align-items: center;
      } */
      .slick-dots {
        /* bottom: 50px; */
        width: auto;
        text-align: left;
        left: 0;
        top: 40%;
        transform: translateY(-50%);
        height: 30px;

        @media (max-width: 899px) {
          /* bottom: -25px; */
          width: 100%;
          text-align: center;
          top: inherit;
          transform: none;
        }

        /* @media (max-width: 599px) {
          bottom: -25px;
        } */
      }

      .slick-disabled {
        filter: invert(72%) sepia(9%) saturate(213%) hue-rotate(175deg) brightness(115%) contrast(80%);
      }
    }
  }

  .people-lft {
    @media (max-width: 899px) {
        text-align: center;
    }
  }
`;

const sliderLoop = [
  {
    sliderContent:
      "AAA Residencies is bringing innovation and luxury to their homes. Their company and staff truly care",
    authorImage: `${assest.reviewr}`,
    authorName: "Anonymous",
    authorLocation: "Alexandria, Va",
  },
  {
    sliderContent:
      "Programs are specific and highly personalized. Staff members are extremely supportive, caring and passionate.",
    authorImage: `${assest.authorImg2}`,
    authorName: "Stephanie Martin",
    authorLocation: "Springfield, VA",
  },
  {
    sliderContent:
      "AAA Residencies provides a great environment for individuals with developmental disabilities. They are focussed on providing the best care and deeply care about their resident's wellbeing.",
    authorImage: `${assest.authorImg}`,
    authorName: "Bradly Edward",
    authorLocation: "Fairfax, Va",
  },
];
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,


};

const WhatPeopleSay = () => {
  return (
    <PeopleSec>
      <Box className="people-sec cmn_gap">
        <Container fixed>
          <Box className="people-outr">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box className="people-lft">
                  <Typography variant="h2" className="cmn-head">
                    What people say <br /> <span> about Us.</span>
                  </Typography>
                  {/* <Typography variant="body1">
                    AAA Residencies was founded in 2019 with one major goal: to provide for individuals with intellectual and developmental.
                  </Typography> */}

                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className="people-rgt">

                  <Slider {...settings} className="people-slider">
                    {sliderLoop.map((item, index) => {
                      return (
                        <Box className="people-slide" key={index}>
                          <Box className="people-inr">
                            <Typography variant="body1">
                              {item.sliderContent}
                            </Typography>
                            <Box className="people-btm">
                              <span className="author-img">
                                <Avatar
                                  alt="Remy Sharp"
                                  src={item.authorImage}
                                  className="people-avatar"
                                />
                              </span>
                              <Box className="people-hdr">
                                <Typography variant="h4">
                                  {item.authorName}
                                </Typography>
                                <Typography variant="h5">
                                  {item.authorLocation}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Slider>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </PeopleSec>
  )
}

export default WhatPeopleSay