export default {
  notfound: "/assets/images/404.svg",
  testicon: "/assets/icons/2082.png",
  testicon1: "/assets/icons/icon-192x192.png",
  testicon2: "/assets/icons/icon-256x256.png",
  testicon3: "/assets/icons/icon-384x384.png",
  testicon4: "/assets/icons/icon-512x512.png",
  image: "/assets/icons/auricular-phone_318-1028.webp",
  aboutImg1: "/assets/images/a2.png",
  aboutImg2: "/assets/images/a1.png",
  aboutImg3: "/assets/images/a3.png",
  Logo: "/assets/images/logo.png",
  btnimg: "/assets/images/btn-img.svg",
  bluedot: "/assets/images/dot.svg",
  skydot: "/assets/images/dot2.svg",
  BtnIcon: "/assets/images/button-icon.svg",
  HomeBanner: "/assets/images/homebanner.png",
  patientsIcon: "/assets/images/icon-patients.svg",
  bluedot2: "/assets/images/dot3.svg",
  tickgrn: "/assets/images/tick-grn.svg",
  authorImg: "/assets/images/author.png",
  authorImg2: "/assets/images/author02.png",
  blackDot: "/assets/images/black-dotted.svg",
  Since: "/assets/images/since.png",
  Curve: "/assets/images/curve-round.svg",
  VideoImg: "/assets/images/video.png",
  PauseIcon: "/assets/images/pause.svg",
  locationIcon: "/assets/images/locationIcon.svg",
  priceIcon: "/assets/images/priceIcon.svg",
  arrowRight: "/assets/images/arrow-rgt.svg",
  arrowleft: "/assets/images/arrow-lft.svg",
  client01: "/assets/images/client01.png",
  client02: "/assets/images/client02.png",
  client03: "/assets/images/client03.png",
  client04: "/assets/images/client04.png",
  client05: "/assets/images/client05.png",
  client06: "/assets/images/client06.png",
  call: "/assets/images/call.svg",
  location: "/assets/images/location.svg",
  mail: "/assets/images/mail.svg",
  homeIcon: "/assets/images/homeIcon.svg",
  arrowGray: "/assets/images/arrowGray.svg",
  Exp: "/assets/images/exp.png",
  followImg: "/assets/images/lv.svg",
  tabUser: "/assets/images/tab-usr.png",
  tabIcon1: "/assets/images/c1.svg",
  tabIcon2: "/assets/images/c2.svg",
  tabIcon3: "/assets/images/c3.svg",
  tabIcon4: "/assets/images/c9.svg",
  tabIcon5: "/assets/images/c5.svg",
  tabIcon6: "/assets/images/c6.svg",
  tabIcon7: "/assets/images/c7.svg",
  tabIcon8: "/assets/images/c8.svg",
  tabIcon9: "/assets/images/re.svg",
  Map: "/assets/images/map.png",
  ArwUp: "/assets/images/arw-up.svg",
  BlueDot: "/assets/images/blue-dot.svg",
  settings: "/assets/images/settings.svg",
  searchIcon: "/assets/images/srch.svg",
  icon11: "/assets/images/icon11.svg",
  icon12: "/assets/images/icon12.svg",
  icon13: "/assets/images/icon18.svg",
  icon14: "/assets/images/icon15.svg",
  icon16: "/assets/images/icon16.svg",
  videoone: "/assets/images/homevideo.mp4",
  homeimg11: "/assets/images/houseisolatedfield12.jpg",
  homeimg14: "/assets/images/houseisolatedfield.jpg",
  reviewr: "/assets/images/imageblank.png",
  homeimg15: "/assets/images/commingsoon.png",
  userIcon: "/assets/images/icon-user.svg",
  clientHappy: "/assets/images/hpy-client.png",
  reviewer01: "/assets/images/reviewer01.png",
  reviewer02: "/assets/images/reviewer02.png",
  reviewstatic: "/assets/images/review-static.png",
  about01: "/assets/images/about01.png",
  about02: "/assets/images/about02.png",
  House01: "/assets/images/house-part01.png",
  House02: "/assets/images/house-part02.png",
  House03: "/assets/images/house-part03.png",
  videoPoster: "/assets/images/video-poster.jpg",

};
