import React from "react";
import { Button, Dialog, DialogProps, Paper, TextField } from "@mui/material";
import Seo from "@/components/Seo/Seo";
import { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import styled from "@emotion/styled";
import { Box, Container } from "@mui/system";
import CustomButton from "@/ui/Buttons/CustomButton";
import { Grid, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useNotiStack from "@/hooks/useNotistack";

import validationText from "@/json/messages/validationText";
import { SubmitHandler, useForm } from "react-hook-form";
const EnqueryDialog = styled(Box)`
  /* text-align: center; */

  .cmn-head {
    text-align: center;
  }

  .MuiTypography-body1 {
    max-width: 756px;
    margin: 0 auto 45px;
  }
  .MuiTextField-root {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    input {
      box-sizing: border-box;
      height: 70px;

      @media (max-width: 599px) {
        height: 50px;
      }
    }

    .MuiInputLabel-root {
      /* transform: translate(14px, 22px) scale(1); */
      color: #65676a;
      font-weight: 400;
      font-size: 16px;
      padding-top: 8px;

      /* @media (max-width: 599px) {
            transform: translate(14px, 14px) scale(1);
        } */

      /* &.Mui-focused {
            transform: translate(14px, -9px) scale(0.75);
        } */
      &.MuiInputLabel-shrink {
        padding-top: 0;
      }
    }

    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.23);
        border-width: 1px;
      }
    }
  }

  .MuiButtonBase-root {
    width: 100%;
    margin-top: 45px;
  }
`;
const ModalCloseBtn = styled(Button)`
  position: absolute;
  right: 15px;
  top: 15px;
  color: #1e294c;
  margin: 0 !important;
  width: auto !important;
  padding: 0;
  min-width: 1px;

  &:hover {
    background-color: #fff !important;
  }
`;

//=== TYPES ====
interface IFormInput {
  name: string;
  email: string;
  subject: string;
  message: string;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email(validationText.error.email_format)
    .required(validationText.error.enter_email),
  name: yup.string().required(validationText.error.name),
  subject: yup.string().required(validationText.error.subject),
  message: yup.string().required(validationText.error.message),
});

function EnquaryModal(props: DialogProps) {
  const { toastSuccess, toastError } = useNotiStack();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInput> = (data: any) => {
    setIsLoading(true);
    fetch("/api/sendgrid", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 200) {
          toastSuccess("Message sent successfully");

          reset();
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toastError(err?.message);
        setIsLoading(false);
      });
  };
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="expDialog"
      >
        <EnqueryDialog>
          <ModalCloseBtn onClick={props.onClose}>
            <CancelIcon />
          </ModalCloseBtn>
          <Typography variant="h2" className="cmn-head">
            Get <span>Started</span>
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <Typography variant="body1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis feugiat sem at dui tempor, ac tristique sapien posuere. Aliquam vel sagittis erat.
                    </Typography> */}
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    {...register("name")}
                    className={errors?.name?.message ? "formRootHide" : "newFormRoot"}
                  />
                  <span className="errormesg">{errors?.name?.message}</span>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    {...register("email")}
                    className={errors?.email?.message ? "formRootHide" : "newFormRoot"}
                  />
                  <span className="errormesg">{errors?.email?.message}</span>
                  <TextField
                    id="outlined-basic"
                    label="Subject"
                    {...register("subject")}
                    className={errors?.subject?.message ? "formRootHide" : "newFormRoot"}
                  />
                  <span className="errormesg">
                    {errors?.subject?.message}
                  </span>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Message"
                    multiline
                    rows={10.3}
                    {...register("message")}
                    className={errors?.message?.message ? "formRootHide" : "newFormRoot"}
                  />
                  <span className="errormesg">
                    {errors?.message?.message}
                  </span>
                </Grid>
              </Grid>
            </Box>
            <CustomButton  loading={isLoading} disabled={isLoading} type="submit">
              <Typography variant="button">Start</Typography>
            </CustomButton>
          </form>
        </EnqueryDialog>
      </Dialog>
    </>
  );
}

export default EnquaryModal;
