export default {
  success: {},
  error: {
    enter_email: "Enter email",
    email_format: "Email format is not correct",
    enter_password: "Enter password",
    phone: "Enter phone",
    username: "Enter username",
    fullName: "Enter Full Name",
    name: "Enter Name",
    subject: "Enter Subject",
    message: "Enter Message",
  },
  warn: {},
  info: {},
};
